html, body { height: 100%; }

/* main landing image */
.background {
  background-image: url('../../images/anna-dudkova--KWX2LlZ_3M-unsplash.jpg');
  background-color: black;
  opacity: 87%;
  filter: brightness(0.78);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: calc(100vh - 96px);
}

.white-text {
  color: whitesmoke;
}

.color-3-background {
  background-color: #dcffe7;
}

.color-2-background {
  background-color: #347248;
}

.vertical-center {
  margin: 0;
  position: relative;
  top: 50%;
  /* -ms-transform: translateY(-50%); */
  -ms-transform: translateY(calc(-50% - 96px));
  /* transform: translateY(-50%); */
  transform: translateY(calc(-50% - 96px));
}

.line-spacing-landing {
  line-height: 160%;
}


.landing-content-container {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 1rem;
  margin-right: 10%;
}

.parent {
  /* height: 30%; */
  /* background: gray; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.parent-main {
  height: 30%;
  /* background: gray; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.child {
  /* background-color: orange; */
  width: 100%;
  height: 40%;
  text-align: center;
}

@media only screen and (max-height: 700px) {
  .parent-main {
    height: 0%;
    padding-top: 200px;
    /* background: gray; */
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .child {
    /* background-color: orange; */
    width: 100%;
    height: 10%;
    text-align: center;
  }
}

.padding-tb-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

#daycare-background {
  background-image: url('../../images/camylla-battani-AoqgGAqrLpU-unsplash.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100vh;
}

#boarding-background {
  background-image: url('../../images/zoritsa-valova-PkalfeaGwE4-unsplash.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100vh;
}

#extra-services-background {
  background-image: url('../../images/darren-richardson-a-VZj8a1wWc-unsplash.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100vh;
}

.title {
  margin: 10%;
  color: white;
  font-weight: 800;
  font-size: 3rem;
  text-shadow: 3px 4px 7px rgba(81,67,21,0.8);
  /* text-shadow: 10px 10px 7px rgba(81,67,21,0.8); */
  /* color: transparent;
  background: #666666;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  text-shadow: 0px 3px 3px rgba(255,255,255,0.5); */
}

@media only screen and (max-width: 1000px) {
  .title {
    margin: 0%;
    color: white;
    font-weight: 800;
    font-size: 2rem;
    text-shadow: 3px 4px 7px rgba(81,67,21,0.8);
    /* text-shadow: 10px 10px 7px rgba(81,67,21,0.8); */
    /* color: transparent;
    background: #666666;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    text-shadow: 0px 3px 3px rgba(255,255,255,0.5); */
  }
}

.center {
  text-align: center;
}

.black-background {
  background-color: black;
}

.padding-top-20 {
  padding-top: 20px;
}

@media only screen and (max-width: 1000px) {
  .parent {
    height: 30%;
    /* background: gray; */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .child {
    /* background-color: orange; */
    width: 100%;
    height: 100%;
    text-align: center;
  }

  #daycare-background {
    background-image: url('../../images/camylla-battani-AoqgGAqrLpU-unsplash.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* width: 100vw; */
    height: 50vh;
  }

  #boarding-background {
    background-image: url('../../images/zoritsa-valova-PkalfeaGwE4-unsplash.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* width: 100vw; */
    height: 50vh;
  }

  #extra-services-background {
    background-image: url('../../images/darren-richardson-a-VZj8a1wWc-unsplash.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* width: 100vw; */
    height: 50vh;
  }

}