.daycare-page-container {
  max-width: 960px;
  margin: auto;
  margin-top: 3.5%;
  padding-bottom: 2.5%;
}

.padding-top-10 {
  padding-top: 1rem;
}

.daycare-background-1 {
  background-image: url('../../images/justin-veenema-NH1d0xX6Ldk-unsplash.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: auto;
  min-width: 300px;
  height: auto;
  min-height: 400px;
  margin:auto;
  margin-top: 2.5%;
  margin-bottom: 2.5%;
}

/* .daycare-background-2 {
  background-image: url();
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: auto;
  min-width: 300px;
  height: auto;
  min-height: 300px;
  margin: auto;
  margin-top: 2.5%;
  margin-bottom: 2.5%;
} */

#daycare-content-container {
  width: auto;
}

.center {
  text-align: center;
}

.padding-top-20 {
  padding-top: 20px;
}

.center-items {
  display: flex;
  align-items: center;
  justify-content: center;
}

.line-spacing-daycare {
  line-height: 130%;
}

