html,body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
  scroll-snap-type: y proximity;
}

@media only screen and (max-width: 960px) {

  html,body {
    /* scroll-snap-type: y proximity;; */
    scroll-snap-type: none;
    /* scroll-padding-top: 30px; */
  }
}

/* fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: 'Roboto', sans-serif;
}

ul {
  font-family: 'Roboto', sans-serif;
}

.color-1 {
  color: #4aa359;
}

.color-2 {
  color: #347248;
}

.color-3 {
  color: #dcffe7;
}

.color-4 {
  color: #a3554a;
}

.color-5 {
  color: #723b34;
}

hr.divider-color-1 {
  border-top: 7px solid;
  border-radius: 5px;
  border-color: #4aa359;
}

hr.divider-color-2 {
  border-top: 7px solid;
  border-radius: 5px;
  border-color: #347248;
}

hr.divider-white {
  border-top: 7px solid;
  border-radius: 5px;
  border-color: whitesmoke;
}

hr.divider-color-3 {
  border-top: 7px solid;
  border-radius: 5px;
  border-color: #dcffe7;
}

hr.divider-color-4 {
  border-top: 7px solid;
  border-radius: 5px;
  border-color: #a3554a;
}

hr.divider-color-5 {
  border-top: 7px solid;
  border-radius: 5px;
  border-color: #723b34;
}

.page-container {
  height: 100vh;
  /* height: calc(100vh - 120px) !important; */
  /* width: 100%; */
  scroll-snap-align: start;
}

#navbar {
  scroll-snap-align: start;
}

@media only screen and (max-width: 1000px) {
  .page-container {
    height: auto;
  }
}

.center {
  text-align: center;
}

.promotion-image {
  background-image: url('../images/anna-dudkova--KWX2LlZ_3M-unsplash.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 500px;
  height: 500px;
  z-index: 0;
}

.contact-page-container {
  max-width: 600px;
}