.hns-page-container {
  max-width: 960px;
  margin: auto;
  margin-top: 3.5%;
  padding-bottom: 2.5%;
}

.center {
  text-align: center;
}

.hns-title-container {
  max-width: 70%;
}

.hns-photo {
  background-image: url('../../images/towfiqu-barbhuiya-ho-p7qLBewk-unsplash.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 50vh;
  z-index: 0;
}