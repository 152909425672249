.es-page-container {
  max-width: 960px;
  margin: auto;
  margin-top: 3.5%;
  padding-bottom: 2.5%;
}

ul {
  margin-bottom: 10px;
}

.center {
  text-align: center;
}