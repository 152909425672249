
.boarding-page-container {
  max-width: 960px;
  margin: auto;
  margin-top: 3.5%;
  padding-bottom: 2.5%;
}

.center {
  text-align: center;
}

.content-container {
  max-width: 960px;
  margin: auto;
  padding-top: 1.25%;
  padding-bottom: 1.25%;
}

#main-boarding-page-container {
  max-width: 960px;
  margin: auto;
  /* margin-top: 3.5%; */
  /* margin-bottom: 3.5%; */
  /* padding-top: 3.5%; */
  /* padding-bottom: 3.5%; */
}

.white-text {
  color: whitesmoke;
}

.color-3-background {
  /* height: 100vh; */
  /* width: 100vw; */
  background-color: #dcffe7;
}

.color-2-background {
  background-color: #347248;
}

.mb-30 {
  margin-bottom: 50px;
}

.accordion-button:not(.collapsed) {
  color: black;
  background-color: #dcffe7;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}